import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import Hero from '../components/hero'
import SubscribeForm from '../components/subscribe-form'
import Listing from '../components/listing'
import Card from '../components/card'
import About from '../components/about'

import SmoresIllustration from '../images/smores-illustration.svg'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      recipes: allMarkdownRemark(
        filter: { fields: { content_type: { eq: "recipes" }}}
        sort: {fields: frontmatter___published_on, order: DESC}
        limit: 1
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            summary
            rating {
              overall
            }
            images: image_gallery {
              thumb: file {
                childImageSharp {
                  gatsbyImageData(width: 380, height: 285, transformOptions: {
                    cropFocus: CENTER
                  })
                }
              }
              alt
            }
          }
        }
      }
      reviews: allMarkdownRemark(
        filter: { fields: { content_type: { eq: "reviews" }}}
        sort: {fields: frontmatter___published_on, order: DESC}
        limit: 5
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            summary
            rating {
              overall
            }
            images: image_gallery {
              thumb: file {
                childImageSharp {
                  gatsbyImageData(width: 247, height: 247, transformOptions: {
                    cropFocus: CENTER
                  })
                }
              }
              alt
            }
          }
        }
      }
    }
  `)

  const socialImage = 'https://www.smorescout.com/images/social/smorescout-summary.png'
  const socialImageAlt = `S’more Scout`

  const metaDescription = (
    `A collection of s’mores recipes, reviews, and ` +
    `ideas. Honest reviews on products and recipes—by the ` +
    `s’mores-obsessed, for the s’mores-obsessed.`
  )

  const reviews = data.reviews.nodes
  const recipe = data.recipes.nodes[0]

  return (
    <Layout headerTheme="chocolate">
      <Seo
        title="S’mores Everything"
        description={metaDescription}
        canonicalUrl={`${data.site.siteMetadata.siteUrl}/`}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:creator',
            content: '@smorescout',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Hero>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} sm={{ span: 5, order: 2 }} lg={5} className="mb-4 mb-md-0">
              <div className="hero-illustration">
                <SmoresIllustration />
              </div>
            </Col>

            <Col xs={12} sm={7} lg={6} className="mb-4 mb-md-0">
              <h1 className="hashtag hero-home-head mb-3">
                <span className="hashtag-word">
                  S’mores
                </span>{' '}
                <span className="hashtag-word">
                  Everything
                </span>
              </h1>
              <p className="lead pb-md-4">
                Get <strong>s’mores <Link to="/recipes/">recipes</Link>,{' '}
                <Link to="/reviews/">reviews</Link>, and{' '}
                <Link to="/ideas/">ideas</Link></strong> in your inbox.
              </p>

              <div className="d-none d-md-block">
                <SubscribeForm />
              </div>
            </Col>

            <Col xs={12} sm={{ order: 3 }} md={7} className="d-md-none">
              <SubscribeForm />
            </Col>
          </Row>
        </Container>
      </Hero>

      <Section className="py-5">
        <Container>
          <Row>
            <Col xs={12} lg={9} xl={8} className="mb-4 mb-lg-0">
              <h2 className="mb-3">
                <Link to="/reviews/" className="link-unstyled">
                  Latest reviews
                </Link>
              </h2>

              {reviews.map(review => {
                return (
                  <div key={review.frontmatter.title} className="mb-4">
                    <Listing
                      slug={review.fields.slug}
                      title={review.frontmatter.title}
                      rating={review.frontmatter.rating.overall}
                      summary={review.frontmatter.summary}
                      image={review.frontmatter.images[0]}
                    />
                  </div>
                )
              })}

              <div className="more">
                <Link to="/reviews/" className="more-link">
                  All s’mores reviews
                </Link>
              </div>
            </Col>

            <Col as="aside" xs={12} lg={3} xl={4}>
              <Row>
                <Col sm={6} lg={12} className="mb-5">
                  <h2 className="h3 mb-3">
                    <Link to="/recipes/" className="link-unstyled">
                      Latest recipe
                    </Link>
                  </h2>

                  <div className="mb-3">
                    <Card
                      slug={recipe.fields.slug}
                      title={recipe.frontmatter.title}
                      rating={recipe.frontmatter.rating.overall}
                      summary={recipe.frontmatter.summary}
                      image={recipe.frontmatter.images[0]}
                      theme="sunny"
                    />
                  </div>

                  <div className="more">
                    <Link to="/recipes/" className="more-link">
                      All s’mores recipes
                    </Link>
                  </div>
                </Col>

                <Col sm={6} lg={12} className="pt-sm-5 pt-lg-0">
                  <About />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
