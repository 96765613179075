import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const About = () => {
  const imgData = useStaticQuery(graphql`
    query AboutQuery {
      allImageSharp(filter: {original: {src: {regex: "/chris-avatar/"}}}) {
        edges {
          node {
            gatsbyImageData(width: 200, height: 200)
          }
        }
      }
    }
  `)

  return (
    <figure className="about">
      <div className="about-photo">
        <GatsbyImage
          image={imgData.allImageSharp.edges[0].node.gatsbyImageData}
          alt="Chris Peters"
          className="about-img"
        />
      </div>

      <figcaption className="about-body">
        <h2 className="about-head h3">
          About me
        </h2>
        <p className="about-desc">
          Hey there, I’m Chris. I started this site to chronicle my exploration
          of the world of s’mores and share with others along the way. I can’t
          get enough of this treat—a rich icon of the nostalgia of late nights,
          campfires, connection, and careless times.
        </p>
      </figcaption>
    </figure>
  )
}

export default About
