import React from 'react'
import PropTypes from 'prop-types'

const Hero = ({ children }) => {
  return (
    <section className="hero">
      {children}
    </section>
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hero
