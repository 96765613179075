import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { GatsbyImage as Image } from 'gatsby-plugin-image'
import { Card } from 'react-bootstrap'

import StarRating from './star-rating'

const MyCard = ({ image, rating, slug, summary, theme, title }) => {
  const className = ['card']

  if (theme === 'sunny') {
    className.push('card-theme-sunny')
  }

  function containerClickHandler() {
    navigate(slug)
  }

  return (
    <div
      className={className.join(' ')}
      role="link"
      tabIndex={0}
      onClick={containerClickHandler}
      onKeyPress={containerClickHandler}
    >
      <div className="card-photo">
        <Image
          image={image.thumb.childImageSharp.gatsbyImageData}
          alt={image.alt}
          className="card-image"
        />
      </div>

      <Card.Body>
        <Card.Title>
          <Link to={slug} className="listing-link">
            {title}
          </Link>
        </Card.Title>

        <div className="card-meta">
          Rating: <StarRating rating={rating} theme="dark" />
        </div>

        <p className="card-summary">
          {summary}
        </p>
      </Card.Body>
    </div>
  )
}

MyCard.defaultProps = {
  theme: 'nebula-light',
}

MyCard.propTypes = {
  rating: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.string,
}

export default MyCard
